import { DOMAINS } from '@/lib/data/domains';
const DEFAULT_TLD = 'com';
export function getTLDFromLocal(locale: string) {
  for (const [_key, value] of Object.entries(DOMAINS)) {
    if (value.locale === locale) {
      return value?.tld ?? DEFAULT_TLD;
    }
  }
  return DEFAULT_TLD;
}

export function getApiFromLocal(locale: string) {
  return process.env.NEXT_PUBLIC_GINDUMAC_API_NO_TLD + getTLDFromLocal(locale);
}
