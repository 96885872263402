import axios from 'axios';

import {
  ApiProductResponse,
  ApiProductsResponse,
  IProductAccountManager,
  ProductItem,
} from '@/lib/types/api';
import {
  IOrderby,
  ProductFilterState,
} from '@/components/forms/Filters/filterSlice';
import { isNil, omit, omitBy } from 'lodash-es';
import { getApiFromLocal } from '../data-transformer/getApiDomainFromLocale';
import { isProduction } from '../common';
import { DEFAULT_ACCOUNT_MANAGER } from '../hooks/useAccountManager';

const DefaultsHeaders = {
  headers: {
    'Content-type': 'application/json',
  },
};

export const DEFAULT_PAGE_SIZE = 36;

interface productsSearchQuery {
  page_size?: number;
  store?: 'COM';
  country?: 'US';
  order_by?: IOrderby;
  machine_category?: string;
  machine_type?: string[];
  brand?: string[];
  model?: string[];
  localization_country?: string[];
  application_type?: string[];
  control_unit_brand?: string[];
  year_range?: string;
  min_price?: number;
  max_price?: number;
  statuses?: number[];
  search_text?: string;
  // TODO: is this needed ?
  user_ip?: string;
}

export const getProductsByForm = async (
  filters: ProductFilterState = {
    page: 1,
  },
  locale: string
) => {
  const query: productsSearchQuery = {
    ...omit(filters, [
      // TODO: refactor ?
      // remove brand,model and application_type since we want to send as an array of ids without the label
      'brand',
      'model',
      'application_type',
      'search',
      // if no machine_category is selected, we don't want to send it to the API as an empty string
      filters.machine_category === '' ? 'machine_category' : '',
      'machine_type',
    ]),
    // send only the ids
    application_type: filters.application_type?.map((item) => item.value),
    brand: filters.brand?.map((item) => item.value),
    model: filters.model?.map((item) => item.value),
    store: 'COM',
    page_size: DEFAULT_PAGE_SIZE,
    statuses: [1, 2, 3, 4, 7, 8, 9, 12, 13],
    order_by: filters.order_by ?? 'recent_added_first',
    search_text: filters.search,
    // send only the value of each item in machine_type
    machine_type: filters.machine_type
      ? filters.machine_type?.map((item) => item.value)
      : undefined,
    localization_country: filters.localization_country
      ? filters.localization_country?.map((item) => item.value)
      : undefined,
  };
  const { data } = await axios.post<ApiProductsResponse>(
    `${getApiFromLocal(locale)}/api/products`,
    omitBy(query, isNil),
    DefaultsHeaders
  );
  return data;
};

export function getProducts({
  page_size = DEFAULT_PAGE_SIZE,
  store = 'COM',
  order_by = 'recent_added_first',
  machine_category,
  statuses = [1, 2, 3, 4, 7, 8, 9, 12, 13],
  brand,
  locale,
}: productsSearchQuery & { locale: string }) {
  const isEmptyFilters = !brand;
  const payloadWithoutEmptyValues = omitBy(
    {
      page_size,
      store,
      order_by,
      machine_category,
      statuses,
      brand,
    },
    (value) => value === undefined || value === null || value === ''
  );

  return fetch(getApiFromLocal(locale) + '/api/products', {
    method: 'POST',
    body: JSON.stringify(payloadWithoutEmptyValues),
    headers: DefaultsHeaders.headers,
  })
    .then(async (response: Response) => {
      if (response.status === 200) {
        const res = (await response.json()) as ApiProductsResponse;
        if (isEmptyFilters && res.data.total_results === 0) {
          throw new Error('No products found for getProducts without filters');
        }
        return res as ApiProductsResponse;
      }
      console.log('fetch products error response:', response);
      console.log(
        'fetch products error w/ payload:',
        payloadWithoutEmptyValues
      );
      throw new Error('Error fetching products');
    })
    .catch(() => {
      throw new Error('Error fetching products');
    });
}

export function avoidRateLimit(delay = 500) {
  if (process.env.NODE_ENV != 'production' || process.env.VERCEL !== '1') {
    return;
  }

  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function getProductDetails(sku: string, locale = 'en-US') {
  const host = getApiFromLocal(locale);
  return axios
    .get(host + '/api/product/' + encodeURIComponent(sku))
    .then((res) => {
      const apiData: ApiProductResponse = res.data;
      if (apiData.status === 'error') {
        console.warn('getProductDetails: status', res.data.status);
        throw new Error('api res.data.status', res.data.status);
      }
      return apiData.data as ProductItem;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return null;
      }
      throw err;
    });
}

export function findAccountManagerByLocale(
  accountManagers: IProductAccountManager[],
  locale?: string
) {
  if (!locale) {
    return null;
  }
  return accountManagers.find(
    (accountManager) =>
      accountManager.locales && accountManager.locales.includes(locale)
  );
}

export async function getAccountManager(
  TLDlocale?: string,
  userLocale?: string
): Promise<IProductAccountManager | null> {
  if (!TLDlocale) {
    throw new Error('Fetch account manager needs a locale');
  }
  const GLOBAL_ACCOUNT_MANAGER_LOCALE = 'en-GB';
  TLDlocale = TLDlocale.replace('en-US', GLOBAL_ACCOUNT_MANAGER_LOCALE);
  const accountManagers = await getCacheAccountManagers();
  const accountManager =
    findAccountManagerByLocale(accountManagers, userLocale) ??
    findAccountManagerByLocale(accountManagers, TLDlocale);

  if (accountManager) {
    return accountManager;
  }
  return (
    findAccountManagerByLocale(
      accountManagers,
      GLOBAL_ACCOUNT_MANAGER_LOCALE
    ) ?? DEFAULT_ACCOUNT_MANAGER
  );
}

export function getCacheAccountManagers(): Promise<IProductAccountManager[]> {
  const api = isProduction()
    ? 'https://www.gindumac.com/api/'
    : 'https://staging.gindumac.com/api/';
  return axios.get(api + 'account-manager').then((res) => {
    return res.data;
  });
}

export async function getAccountManagers(): Promise<IProductAccountManager[]> {
  return await axios
    .get(process.env.NEXT_PUBLIC_CRM_API_URL + 'account-manager/')
    .then((res) => {
      const account_manager_response = res.data;
      if (account_manager_response) {
        return account_manager_response;
      }
      throw new Error('No account managers found');
    });
}

export function getCategories() {
  return [
    {
      order_number: 1,
      name: 'Machine Tools',
      id: 'a0d9d006-465c-4044-9d24-ef0eb4470121',
      slug: 'machine-tool',
      href: '/products/used-machine-tools',
    },
    {
      order_number: 2,
      name: 'Sheet Metal Machinery',
      id: '8cfe086d-956f-4110-b3b4-0899d64d3b50',
      slug: 'sheet-metal',
      href: '/products/used-sheet-metal-machinery',
    },
    {
      order_number: 3,
      name: 'Plastics Processing Machinery',
      id: '21928eda-0aa4-45d9-b2d6-76ac85fcc4ec',
      slug: 'plastics-processing',
      href: '/products/used-plastics-processing-machinery',
    },
    {
      order_number: 4,
      name: 'Automation Equipment',
      id: '6236ba73-fe33-4c26-a243-65676f089698',
      slug: 'automation-equipment',
      href: '/products/used-automation-equipment',
    },
    {
      order_number: 5,
      name: 'Wood Working',
      id: '20d8339e-f71e-4651-9571-1978bb97860f',
      slug: 'wood-working',
      href: '/products/used-wood-working-machinery',
    },
  ];
}

export async function getRelatedProductsBySKU(sku: string, locale: string) {
  const products = await axios
    .get(getApiFromLocal(locale) + '/api/related-products/' + sku)
    .then((res) => {
      return res.data.status == 'success' && res.data.data.products.length
        ? (res.data.data.products as any[])
        : [];
    })
    .catch(function (_err) {
      return [];
    });
  return products;
}

interface QueryI {
  brand?: string[];
  application_type?: string[];
  machine_type?: string[];
}

export async function getRelatedProductsByForm(
  filters: ProductFilterState = {
    page: 1,
  },
  locale: string
) {
  const query: QueryI = {};
  if (filters.brand && filters.brand?.[0]?.value) {
    query.brand = [filters.brand[0].value];
  }
  if (filters.application_type && filters.application_type?.[0]?.value) {
    query.application_type = filters.application_type.map((item) => item.value);
  }
  if (filters.machine_type && filters.machine_type?.[0]?.value) {
    query.machine_type = filters.machine_type.map((item) => item.value);
  }
  return axios.post<ApiProductsResponse>(
    getApiFromLocal(locale) + '/api/related-products',
    query,
    DefaultsHeaders
  );
}

interface namaAndSlug {
  name: string;
  slug: string;
}

interface ApiSuggestionsReponse {
  status: 'success' | 'error';
  data: {
    brands: namaAndSlug[];
    models: namaAndSlug[];
    application_types: namaAndSlug[];
  };
}
export async function fetchSuggestions(
  search_text: string,
  locale: string
): Promise<ApiSuggestionsReponse> {
  const response = await fetch(
    getApiFromLocal(locale) + '/api/v2/product/filter-suggester',
    {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/json',
      },
      body: JSON.stringify({ search_text }),
      method: 'POST',
    }
  );
  return response.json();
}
