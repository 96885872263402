import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Logo } from '../layout/Layout';
type DrawerProps = {
  title?: string | ReactNode;
  description?: string;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'full' | '4/5';
  hasLogo?: boolean;
};

export default function Drawer({
  title = '',
  description = '',
  children,
  isOpen,
  setIsOpen,
  size = 'sm',
  hasLogo,
}: DrawerProps) {
  const hasDrawerTopbar = title || size !== 'xs';
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        unmount={false}
        onClose={() => setIsOpen(false)}
        className='no-print fixed inset-0 z-30 overflow-y-auto'
      >
        <div
          className={classNames('flex h-screen', {
            'w-3/4': ['sm', 'md', 'lg', 'xs'].includes(size),
            'w-full': size === 'full',
            'w-4/5': size === '4/5',
          })}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-in duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-30'
            entered='opacity-30'
            leave='transition-opacity ease-out duration-300'
            leaveFrom='opacity-30'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 z-40 bg-[#00000088]' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div
              className={classNames(
                'z-50 flex w-full flex-col justify-between overflow-hidden bg-white text-left align-middle shadow-xl',
                {
                  'max-w-xs': size === 'xs',
                  'p-6': size !== 'xs',
                  'max-w-sm': size === 'sm',
                  'max-w-md': size === 'md',
                  'max-w-lg': size === 'lg',
                  'max-w-full': size === 'full',
                }
              )}
            >
              <div>
                {hasDrawerTopbar && (
                  <div className='flex h-11'>
                    {hasLogo && (
                      <div className='mt-[-2rem]'>
                        <Logo />
                      </div>
                    )}
                    <Dialog.Title className='text-2xl font-bold text-blue-500 md:text-4xl'>
                      {title}
                    </Dialog.Title>

                    {size !== 'xs' && (
                      <button
                        type='button'
                        className='relative left-4 mb-2 ml-auto mr-2 inline-flex items-center rounded-full bg-brand-500 p-2.5 text-center text-sm font-medium text-white hover:bg-brand-800 focus:outline-none focus:ring-4 focus:ring-blue-300'
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <AiOutlineClose className='h-4 w-4' />
                        <span className='sr-only'>Close</span>
                      </button>
                    )}
                  </div>
                )}

                <Dialog.Description>{description}</Dialog.Description>
                <div
                  className={classNames(
                    'overflow-auto',
                    hasDrawerTopbar ? 'h-[calc(100vh-4rem)]' : 'h-full'
                  )}
                >
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
