import classNames from 'classnames';
import clsx from 'clsx';
import Link from 'next-translate-routes-multi-domain/link';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import {
  ImFacebook,
  ImGoogle,
  ImInstagram,
  ImLinkedin,
  ImTwitter,
  ImYoutube,
} from 'react-icons/im';
import { MdMenu, MdOutlineArrowDropDown } from 'react-icons/md';
import useScrollPosition from '@/lib/hooks/useScrollPosition';
import LanguageSelector from '@/components/i18n/LanguageSelector';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { MobileNavbar } from './MobileNavbar';
import Drawer from '../ui/Drawer';
import NewsletterForm from '../forms/NewsLetter/NewsletterForm';
import NavbarDrawer from '../ui/NavbarDrawer';
import {
  getNewsRoomLink,
  telephoneLink,
} from '@/lib/data-transformer/gindumacUtils';
import { setIsNewsletterModalOpen } from '../forms/NewsLetter/modalSlice';
import { useAppDispatch } from '@/redux/hooks';
import {
  analyticsOpenNewsletterSub,
  gtagContactEvent,
} from '@/lib/utilities/analytics/gtm';
import CookieConsent from '../Analytics/CookieConsent';
import { analyticsButtonClick } from '../Analytics/GoogleAnalytics';
import { MyGindumacLogo } from '../mygindumac/MyGindumacLogo';
import { useIsMyGindumac } from '@/lib/hooks/useIsMyGindumac';
import UserMenu from '../mygindumac/UserMenu';
import GlobalContactUsButton from '../mygindumac/ui/GlobalContactUsButton';
import Sidebar from '../ui/Menus/Sidebar';
export default function Layout({
  isNavbarTransparent,
  disableNewsletterForm,
  disableBackToTop,
  children,
  logo,
  links,
}: {
  isNavbarTransparent?: boolean;
  children: ReactNode;
  disableNewsletterForm?: boolean;
  disableBackToTop?: boolean;
  logo?: ReactNode;
  links?: ReactNode[];
}) {
  const [showMobileNavbar, setShowMobileNavbar] = useState(false);
  const isFixed = isNavbarTransparent;
  const scrollPosition = useScrollPosition();
  isNavbarTransparent = isNavbarTransparent && scrollPosition < 10;
  const { t } = useTranslation('products');
  const { locale } = useRouter();
  const contactEmail = t('products:HEADER_SUPPORT_CONTACT_WITH_US_EMAIL');
  const dispatch = useAppDispatch();
  return (
    <>
      {/* Navbar Gindumac */}

      <div className=''>
        <header
          className={classNames({
            'no-print top-0 z-30 w-full transition duration-200': true,
            'sticky bg-white ': !isNavbarTransparent,
            'bg-transparent': isNavbarTransparent,
            '!fixed': isFixed,
          })}
        >
          <nav
            className={clsx({
              'z-10 flex h-20 w-full items-center justify-between px-4 md:px-8':
                true,
              'border-b-2 ': !isNavbarTransparent,
            })}
          >
            <button
              className='xl:hidden'
              onClick={() => setShowMobileNavbar((isOpen) => !isOpen)}
              aria-label='Open mobile navbar'
            >
              <MdMenu
                className={classNames({
                  'h-10 w-10': true,
                  'text-white': isNavbarTransparent,
                })}
              />
            </button>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            {logo ?? <Logo isWhite={isNavbarTransparent} />}
            {!links ? (
              <>
                <div
                  className={classNames({
                    'text-nav ml-auto mr-4 hidden space-x-4 divide-x-2 border-r pr-4 xl:flex':
                      true,
                    '!text-white': isNavbarTransparent,
                  })}
                >
                  <button
                    className='flex items-center rounded bg-brand-800 px-4 py-1 uppercase text-white transition
                 duration-300 hover:bg-[#CCCCCC] hover:text-brand-500'
                    onClick={() => {
                      analyticsOpenNewsletterSub();
                      dispatch(setIsNewsletterModalOpen(true));
                    }}
                  >
                    {t('products:HEADER_BTN_SIGN_UP')}
                  </button>
                  <div className='flex items-center space-x-4 pl-4'>
                    <Dropdown
                      title={t('products:HEADER_ABOUT_GINDUMAC')}
                      links={[
                        {
                          href: '/who-we-are',
                          title: t('products:CORPORATE_WHO_WE_ARE'),
                        },
                        {
                          href: '/machine-categories',
                          title: t(
                            'products:CORPORATE_MACHINE_CATEGORIES_TITLE'
                          ),
                        },
                      ]}
                    />
                    <Dropdown
                      title={t('products:SERVICES_GINDUMAC_MENU')}
                      links={[
                        {
                          href: '/we-buy-your-project',
                          title: t('products:BUY_YOUR_PROJECT_TITLE'),
                        },
                        {
                          href: '/financing-options',
                          title: t(
                            'products:CORPORATE_FINANCING_OPTIONS_TITLE'
                          ),
                        },
                        {
                          href: '/logistic-services',
                          title: t(
                            'products:CORPORATE_LOGISTICS_SERVICES_TITLE'
                          ),
                        },
                        {
                          href: '/premium-inspection',
                          title: t(
                            'products:CORPORATE_PREMIUM_INSPECTION_TITLE'
                          ),
                        },
                      ]}
                    />
                    <Link href='/careers'>
                      <a>{t('products:CAREERS_TITLE')}</a>
                    </Link>
                    <a
                      href={getNewsRoomLink(locale)}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('products:NEWSROOM_NAME')}
                    </a>
                    <Contact contactEmail={contactEmail} />
                  </div>
                  <div className='flex items-center space-x-4 pl-4'>
                    <NavLink
                      href='/selling-process'
                      color='secondary'
                      label={t('products:HEADER_BTN_SELL')}
                      classes='text-brand-500'
                    />
                    <NavLink
                      href='/products'
                      color='brand'
                      label={t('products:HEADER_BTN_BUY')}
                    />
                  </div>
                </div>
                <div className='items-centter flex space-x-4'>
                  <UserMenu isNavbarTransparent={isNavbarTransparent} />
                  <LanguageSelector isNavbarTransparent={isNavbarTransparent} />
                </div>
              </>
            ) : (
              <div className='flex items-center space-x-4 pl-4'>
                {links.map((link) => link)}
              </div>
            )}
          </nav>
          <Drawer
            isOpen={showMobileNavbar}
            setIsOpen={() => setShowMobileNavbar(false)}
            size='xs'
          >
            <MobileNavbar />
          </Drawer>
        </header>
        <div className='flex'>
          <Sidebar />
          <div
            className={clsx('flex-1 tracking-wider', {
              'brightness-50': showMobileNavbar,
            })}
            onClick={() => setShowMobileNavbar(false)}
          >
            {children}
            <GlobalContactUsButton />
            <footer className='no-print'>
              {!disableNewsletterForm && <NewsletterForm />}
              {!disableBackToTop && <BackToTop />}
              <div className='bg-white px-4 py-8 text-gray-700'>
                <div className='flex w-full flex-col items-center justify-around space-y-4 md:flex-row md:space-y-0'>
                  <ul className='text-center md:text-left'>
                    <li>GINDUMAC GmbH</li>
                    <li>Trippstadter Strasse 110</li>
                    <li>67663 Kaiserslautern</li>
                    <li>{t('products:Germany')}</li>
                  </ul>
                  <ul className='text-center uppercase md:text-left'>
                    <li>
                      <Link href='/who-we-are'>
                        <a>{t('products:HEADER_ABOUT_GINDUMAC')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/careers'>
                        <a>{t('products:CAREERS_TITLE')}</a>
                      </Link>
                    </li>
                    <li>
                      <a
                        target='_blank'
                        href={getNewsRoomLink(locale)}
                        rel='noreferrer'
                      >
                        NEWSROOM
                      </a>
                    </li>
                  </ul>
                  <ul className='text-center uppercase md:text-left'>
                    <li>
                      <Link href='/legal'>
                        <a>{t('seo:FOOTER_LEGAL')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/general-terms-and-conditions-of-sale'>
                        <a>{t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_SALE')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/general-terms-and-conditions-of-purchase'>
                        <a>
                          {t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_PURCHASE')}
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/privacy'>{t('seo:FOOTER_PRIVACY')}</Link>
                    </li>
                    <li>
                      <Link href='/cookie-policy'>
                        {t('products:FOOTER_COOKIES_POLICY')}
                      </Link>
                    </li>
                  </ul>
                  <ul className='text-center md:text-left'>
                    <li>
                      <a
                        href={'mailto:' + contactEmail}
                        onClick={gtagContactEvent}
                      >
                        {contactEmail}
                      </a>
                    </li>
                    <li>
                      <a href={telephoneLink(t('products:HEADER_PHONE'))}>
                        {t('products:HEADER_PHONE')}
                      </a>
                    </li>
                  </ul>
                </div>
                <hr className='my-6' />
                <div className='flex w-full justify-around'>
                  <ul className='flex flex-row flex-wrap items-center space-x-4'>
                    <li className='w-full md:w-auto'>
                      {t('products:FOOTER_TITLE_SOCIAL')}
                    </li>
                    <li>
                      <a
                        href='https://www.linkedin.com/company/gindumac-gmbh'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Linkedin profile'
                      >
                        <ImLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.google.com/maps/place/GINDUMAC+GmbH/@49.4323562,7.7492869,17z/data=!3m2!4b1!5s0x479612ad5fa94551:0xa48b61c0f94b7943!4m6!3m5!1s0x479612ad5c0fe2b3:0xb7c60fb5e4aa40e7!8m2!3d49.4323528!4d7.7537716!16s%2Fg%2F11swc9wcbn?coh=164777&entry=tt&shorturl=1'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Google Plus'
                      >
                        <ImGoogle />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.instagram.com/gindumac/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Xing profile'
                      >
                        <ImInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.youtube.com/channel/UCMZfx7eN3p4q01FSTFFf3Lw'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Xing profile'
                      >
                        <ImYoutube />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.facebook.com/gindumac/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Facebook page'
                      >
                        <ImFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://twitter.com/gindumac'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Twitter profile'
                      >
                        <ImTwitter />
                      </a>
                    </li>
                  </ul>
                  <ul className='flex flex-col items-center space-x-4 md:flex-row'>
                    <li>
                      <span>{t('products:FOOTER_TITLE_MEMBERSHIP')}</span>
                    </li>
                    <li>
                      <a
                        href='http://www.vdwf.de'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          analyticsButtonClick('VDWF logo');
                        }}
                      >
                        <div className='h-12'>
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img
                            src='/images/vdwf_logo_werkzeugmacher_rgb.jpg'
                            alt='vdwf_logo_werkzeugmacher'
                          />
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
}

export function NavLink({
  label,
  href,
  color,
  classes,
}: {
  label: string;
  href: string;
  color?: Colors;
  classes?: string;
}) {
  return (
    <Link href={href}>
      <a className={getClassNameByColor(color) + ' ' + classes}>{label}</a>
    </Link>
  );
}

type Colors = 'brand' | 'secondary';
export function getClassNameByColor(color?: Colors) {
  return classNames(
    'flex items-center justify-center rounded border-[3px] px-4 py-1 transition duration-300 font-primary',
    {
      'border-brand-500 hover:bg-brand-500 hover:text-white': color === 'brand',
      'border-secondary-500 bg-secondary-500 hover:bg-white':
        color === 'secondary',
    }
  );
}

function BackToTop() {
  const { t } = useTranslation();
  return (
    <div
      className='flex cursor-pointer items-center justify-center bg-[#2b2b2b] p-5 hover:bg-[#3B3B3B]'
      onClick={() => {
        //  Scroll to top of the page smoothly
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <div className=' text-white'>{t('products:FOOTER_BACK_TO_TOP')}</div>
    </div>
  );
}

export function Logo({
  isWhite = false,
  hideMyGindumac,
}: {
  isWhite?: boolean;
  hideMyGindumac?: boolean;
}) {
  const isMyGindumacPage = useIsMyGindumac();
  return isMyGindumacPage ? (
    hideMyGindumac ? null : (
      <MyGindumacLogo />
    )
  ) : (
    <a
      href='/'
      className='mx-4 border-none outline-none ring-0 focus:border-transparent focus:outline-none focus:ring-0'
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src='/svg/logo/logo.svg'
        alt='Logo - GINDUMAC - Global Industry Machinery Cluster'
        title='Logo - GINDUMAC - Global Industry Machinery Cluster'
        className={classNames({
          'h-10': true,
          'brightness-[5]': isWhite,
        })}
      />
    </a>
  );
}

function Contact({ contactEmail }: { contactEmail: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('products');

  return (
    <>
      <a href='#' onClick={() => setIsOpen(true)}>
        {t('products:HEADER_SUPPORT')}
      </a>
      <NavbarDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className='my-6 flex flex-col items-center justify-center space-y-4'>
          <li>
            <h5 className='text-center font-secondary text-2xl font-extralight text-brand-500'>
              {t('products:HEADER_SUPPORT_CONTACT_WITH_US_HEADER')}
            </h5>
            <p className='text-center text-muted-500'>
              {t('products:HEADER_SUPPORT_CONTACT_WITH_US_SUBHEADER')}
            </p>
          </li>
          <div className='mx-auto grid max-w-3xl grid-cols-2 gap-4'>
            <li className='flex flex-col items-center justify-center bg-[#f6f6f6] p-4 hover:bg-[#E6E6E6]'>
              <a href={telephoneLink(t('products:HEADER_PHONE'))}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`/svg/icons-08.svg`}
                  alt={t('products:HEADER_PHONE')}
                  className='h-28'
                />
                <span>{t('products:HEADER_PHONE')}</span>
              </a>
            </li>
            <li className='flex flex-col items-center justify-center bg-[#f6f6f6] p-4 hover:bg-[#E6E6E6]'>
              <a onClick={gtagContactEvent} href={'mailto:' + contactEmail}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`/svg/icons-19.svg`}
                  alt={contactEmail}
                  className='h-28'
                />
                <span>{contactEmail}</span>
              </a>
            </li>
          </div>
        </ul>
      </NavbarDrawer>
    </>
  );
}

// tailwind and headless ui menu dropdown component

function Dropdown({
  title,
  links,
}: {
  title: string;
  links: { title: string; href: string }[];
}) {
  return (
    <div className='group relative inline-block'>
      <button className='inline-flex items-center rounded '>
        <span className='mr-1 uppercase'>{title}</span>
        <MdOutlineArrowDropDown className='h-4 w-4' />
      </button>
      <ul className='absolute hidden w-48 border bg-white pt-1 text-gray-700 group-hover:block'>
        {links.map((link) => (
          <li className='' key={title + link.title}>
            <Link href={link.href}>
              <a className='whitespace-no-wrap block bg-white px-8 py-4 hover:bg-brand-500 hover:text-white'>
                {link.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
