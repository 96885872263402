import {
  getNewsRoomLink,
  telephoneLink,
} from '@/lib/data-transformer/gindumacUtils';
import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next-translate-routes-multi-domain/link';
import { useRouter } from 'next/router';
import { AiFillCaretDown } from 'react-icons/ai';
import { Logo } from './Layout';
import { useAppDispatch } from '@/redux/hooks';
import { setIsNewsletterModalOpen } from '../forms/NewsLetter/modalSlice';
import {
  analyticsOpenNewsletterSub,
  gtagContactEvent,
} from '@/lib/utilities/analytics/gtm';
import { useUser } from '@auth0/nextjs-auth0/client';
import { LogoutIcon } from '@heroicons/react/solid';

export function MobileNavbar() {
  const { t } = useTranslation('products');
  const { locale } = useRouter();
  const contactEmail = t('products:HEADER_SUPPORT_CONTACT_WITH_US_EMAIL');
  const dispatch = useAppDispatch();
  const { user } = useUser();
  return (
    <ul className='no-print h-screen w-full items-center bg-white duration-300 ease-in-out'>
      <li className='mb-6 flex items-center justify-center p-4'>
        <Logo />
      </li>
      <li className='mb-4 flex w-full flex-col space-y-4 px-4'>
        <Link href='/selling-process'>
          <a className='w-full border-2 border-brand-500 px-8 py-2 text-center text-lg font-normal uppercase text-brand-500 transition duration-200 hover:bg-brand-500 hover:text-white'>
            {t('products:HEADER_BTN_SELL')}
          </a>
        </Link>
        <Link href='/products'>
          <a className='w-full border-2 border-brand-500 px-8 py-2 text-center text-lg font-normal uppercase text-brand-500 transition duration-200 hover:bg-brand-500 hover:text-white'>
            {t('products:HEADER_BTN_BUY')}
          </a>
        </Link>
      </li>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full'>
              <MobileNavLink title='About Gindumac' isOpen={open} />
            </Disclosure.Button>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel>
                <MobileNavLink
                  href='/who-we-are'
                  title={t('products:CORPORATE_WHO_WE_ARE')}
                />
                <MobileNavLink
                  href='/machine-categories'
                  title={t('products:CORPORATE_MACHINE_CATEGORIES_TITLE')}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full'>
              <MobileNavLink
                title={t('products:SERVICES_GINDUMAC_MENU')}
                isOpen={open}
              />
            </Disclosure.Button>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel>
                <MobileNavLink
                  href='/we-buy-your-project'
                  title={t('products:BUY_YOUR_PROJECT_TITLE')}
                />
                <MobileNavLink
                  href='/financing-options'
                  title={t('products:CORPORATE_FINANCING_OPTIONS_TITLE')}
                />
                <MobileNavLink
                  href='/logistic-services'
                  title={t('products:CORPORATE_LOGISTICS_SERVICES_TITLE')}
                />
                <MobileNavLink
                  href='/premium-inspection'
                  title={t('products:CORPORATE_PREMIUM_INSPECTION_TITLE')}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <MobileNavLink href='/careers' title={t('products:CAREERS_TITLE')} />
      <a
        href={getNewsRoomLink(locale)}
        target='_blank'
        rel='noreferrer'
        className='block w-full p-4 text-left font-primary uppercase text-slate-600 hover:bg-slate-200'
      >
        {t('products:NEWSROOM_NAME')}
      </a>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='w-full'>
              <MobileNavLink
                title={t('products:HEADER_SUPPORT')}
                isOpen={open}
              />
            </Disclosure.Button>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel>
                <ul>
                  <a
                    className='block w-full p-4 text-left font-primary uppercase text-slate-600 hover:bg-slate-200'
                    href={telephoneLink(t('products:HEADER_PHONE'))}
                  >
                    {t('products:HEADER_PHONE')}
                  </a>
                  <a
                    className='block w-full p-4 text-left font-primary uppercase text-slate-600 hover:bg-slate-200'
                    href={'mailto:' + contactEmail}
                    onClick={gtagContactEvent}
                  >
                    {contactEmail}
                  </a>
                </ul>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <li className='my-4 flex w-full flex-col space-y-4 px-4 '>
        <button
          className='w-full border-2 border-slate-400 bg-brand-800 px-8 py-2 text-center text-lg font-normal uppercase text-white transition duration-200 hover:bg-[#CCCCCC]
          hover:text-brand-800'
          onClick={() => {
            analyticsOpenNewsletterSub();

            dispatch(setIsNewsletterModalOpen(true));
          }}
        >
          {t('products:HEADER_BTN_SIGN_UP')}
        </button>
      </li>
      <MobileNavLink href='/legal' title={t('seo:FOOTER_LEGAL')} />
      <MobileNavLink
        href='/terms-and-conditions-of-sale'
        title={t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_SALE')}
      />
      <MobileNavLink
        href='/terms-and-conditions-of-purchase'
        title={t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_PURCHASE')}
      />
      <MobileNavLink href='/privacy' title={t('seo:FOOTER_PRIVACY')} />
      <MobileNavLink
        href='/cookie-policy'
        title={t('products:FOOTER_COOKIES_POLICY')}
      />
      {user && (
        <MobileNavLink
          href='/api/auth/logout'
          title={t('products:LOGOUT')}
          icon={<LogoutIcon className='mr-2 h-4 w-4' />}
        />
      )}
    </ul>
  );
}

export function MobileNavLink({
  title,
  href,
  isOpen,
  icon,
  container,
}: {
  title: React.ReactNode;
  href?: string;
  isOpen?: boolean;
  icon?: React.ReactNode;
  container?: string;
}) {
  return (
    <li
      className={classNames(
        'flex w-full items-center justify-center',
        container && 'px-4'
      )}
    >
      {href ? (
        <Link href={href}>
          <a
            className={classNames(
              container
                ? container
                : 'flex w-full items-center justify-start space-x-4 p-4 text-left font-primary uppercase text-slate-600 hover:bg-slate-200'
            )}
          >
            {icon}
            {title}
          </a>
        </Link>
      ) : (
        <div className='flex w-full items-center space-x-4 p-4 text-left font-primary uppercase text-slate-600 hover:bg-slate-200'>
          <AiFillCaretDown
            className={classNames({
              'rotate-180 transform duration-100': isOpen,
              'cursor-pointer text-brand-500': true,
            })}
          />
          <span>{title}</span>
        </div>
      )}
    </li>
  );
}
