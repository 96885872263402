/* eslint-disable @typescript-eslint/no-var-requires */
const { getDomains } = require('./src/lib/server/getDomains');
const domains = getDomains();
const { LOCALES } = require('./src/lib/data/domains');
const {
  isDevelopment,
  isTesting,
  isProduction,
  isStaging,
} = require('./src/lib/common');
const { captureException } = require('@sentry/nextjs');

let missingKeys = [];

module.exports = {
  locales: LOCALES,
  defaultLocale: 'en-US',
  domains: domains,
  pages: {
    '*': ['products', 'seo'],
    'rgx:^/my': ['mygindumac'],
    'rgx:^/circularclub': ['circular-club', 'mygindumac', 'products'],
    '/general-terms-and-conditions-of-purchase': ['terms'],
    '/general-terms-and-conditions-of-sale': ['terms'],
  },
  // throw an error if translation is missing for next-translate

  logger: (message) => {
    if (typeof window !== 'undefined') {
      if (isTesting() || isDevelopment()) {
        console.warn(
          `Missing translation: ${message.namespace}:${message.i18nKey}`
        );
        // store in an array all the missing translations and console log the array when no more translations are added to it for 5 seconds
        missingKeys.push(message.i18nKey);
        setTimeout(() => {
          if (missingKeys.length > 0) {
            throw new Error(
              `Missing translation: ${missingKeys
                .filter(onlyUnique)
                .join(', ')}`
            );
          }
        }, 5000);
      } else if (isProduction() || isStaging()) {
        // variable with 1% of chance to be true
        const shouldCapture = Math.random() < 0.01;
        if (shouldCapture) {
          setTimeout(() => {
            if (missingKeys.length > 0) {
              captureException(
                new Error(
                  `Missing translation: ${missingKeys
                    .filter(onlyUnique)
                    .join(', ')}`
                )
              );
            }
          }, 5000);
        }
      }
    }
  },
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
